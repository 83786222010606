import { PageQoModel } from '../common/CommonModel';
import { DatumModel } from '../common/DatumModel';

export interface TenantModel extends DatumModel {
  code: string;
  name: string;
  type: string;
  status: string;
  sysRoleId: string;
}

export interface TenantQoModel extends TenantModel {
  page?: PageQoModel;
}

export const TenantData: TenantModel = {
  code: '',
  name: '',
  type: '',
  status: '',
  sysRoleId: '',
  id: ''
};

export const TenantQoData: TenantQoModel = TenantData;
