var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "70%",
        "close-on-click-modal": false,
        "destroy-on-close": true,
        center: true,
        title: _vm.currtent
          ? _vm.$t("lang_edit_tenant")
          : _vm.$t("lang_add_tenant"),
      },
      on: { close: _vm.handleCloseDialog },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
          ref: "formRef",
          attrs: {
            model: _vm.tenantData,
            "label-suffix": "：",
            rules: _vm.formRules,
            "label-width": "150px",
          },
        },
        [
          _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_auth_manage_tenant_name"),
                            prop: "name",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "input",
                            attrs: { placeholder: _vm.$t("lang_please_enter") },
                            model: {
                              value: _vm.tenantData.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.tenantData, "name", $$v)
                              },
                              expression: "tenantData.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_auth_manage_tenant_code"),
                            prop: "code",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "input",
                            attrs: { placeholder: _vm.$t("lang_please_enter") },
                            model: {
                              value: _vm.tenantData.code,
                              callback: function ($$v) {
                                _vm.$set(_vm.tenantData, "code", $$v)
                              },
                              expression: "tenantData.code",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("lang_tenant_type"),
                        prop: "type",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t(
                              "lang_please_select_a_tenant_type"
                            ),
                            clearable: "",
                          },
                          model: {
                            value: _vm.tenantData.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.tenantData, "type", $$v)
                            },
                            expression: "tenantData.type",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: _vm.$t("lang_inside"), value: "0" },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("lang_external"),
                              value: "1",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_tenant_main_application"),
                            prop: "sort",
                          },
                        },
                        [
                          _c("el-cascader", {
                            attrs: {
                              options: _vm.treeList,
                              props: {
                                value: "id",
                                label: "displayName",
                                children: "applications",
                                checkStrictly: true,
                              },
                              clearable: "",
                            },
                            on: { change: _vm.selectApplication },
                            model: {
                              value: _vm.tenantData.applicationId,
                              callback: function ($$v) {
                                _vm.$set(_vm.tenantData, "applicationId", $$v)
                              },
                              expression: "tenantData.applicationId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("lang_sort"), prop: "sort" } },
                        [
                          _c("el-input-number", {
                            model: {
                              value: _vm.tenantData.sort,
                              callback: function ($$v) {
                                _vm.$set(_vm.tenantData, "sort", $$v)
                              },
                              expression: "tenantData.sort",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    { attrs: { id: "icon", label: "logo", prop: "logo" } },
                    [
                      _c("glb-img-upload", {
                        attrs: {
                          "upload-url": _vm.uploadUrl,
                          "pub-preview-url": _vm.pubPreviewUrl,
                          maxUploadSize: 1,
                        },
                        model: {
                          value: _vm.tenantData.logo,
                          callback: function ($$v) {
                            _vm.$set(_vm.tenantData, "logo", $$v)
                          },
                          expression: "tenantData.logo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }