
































































































































































import {
  TenantPage,
  DeleteTenant,
  AccessTenant,
  SetTenantSysRole,
} from "@/api/system/TenantApi";
import { getRoleList } from "@/api/system/RoleApi";
import page from "@/mixins/page";
import {
  TenantModel,
  TenantData,
  TenantQoData,
  TenantQoModel,
} from "@/models/system/TenantModel";
import Component, { mixins } from "vue-class-component";
import { RoleModel } from "../../models/system/RoleModel";
import RoleForm from "@/views/system/role-form.vue";
import TenantForm from "./tenant-form.vue";
import AppTypeApi from "@/api/application/AppTypeApi";
@Component({
  name: "TenantAdmin",
  components: { RoleForm, TenantForm },
})
export default class TenantAdmin extends mixins(page) {
  private loadingOuter: boolean = false;
  private roleSettingVisible: boolean = false;
  private roleFormDiglogVisible: boolean = false;

  private tenantSearchModel: TenantQoModel = TenantQoData;
  private tenantListData: TenantModel[] = [];
  private selectedTenant: TenantModel = TenantData;
  private roles: RoleModel[] = [];
  // 是否显示租户新增编辑弹框
  isShowAddEdit: boolean = false;
  // 当前选中的数据
  currtent: any = null;
  treeList: any = [];
  created(): void {
    this.getTenantPage();
  }

  private initRoleList(): void {
    let param = { tenantId: this.selectedTenant.id };
    getRoleList(param).then((res: Record<string, any>) => {
      if (res.code == "1" && res.data) {
        this.roles = res.data;
      } else {
        this.$message.warning(res.message);
      }
    });
  }

  private handleSearch(): void {
    this.pageIndex = 1;
    this.getTenantPage();
  }

  // 获取表单列表
  private getTenantPage(): void {
    let params: TenantQoModel = {
      ...this.tenantSearchModel,
      page: {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      },
    };
    this.loadingOuter = true;
    TenantPage(params).then((res: Record<string, any>) => {
      if (res.code == "1" && res.data) {
        this.loadingOuter = false;
        this.tenantListData = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.warning(res.message);
      }
    });
  }

  /**
   * 点击新增
   */
  handleAdd(row: any) {
    this.currtent = row;
    this.isShowAddEdit = true;
    AppTypeApi.tree({ name: "" }).then((reg: any) => {
      if (reg.code=="1") {
        this.treeList=reg.data
      }
    });
  }

  private deleteTenant(tenantId: string): void {
    DeleteTenant(tenantId).then((res: Record<string, any>) => {
      if (res.code == "1") {
        this.$message.success(res.message);
        this.getTenantPage();
      } else {
        this.$message.warning(res.message);
      }
    });
  }

  private accessTenant(tenantId: string): void {
    AccessTenant(tenantId).then((res: Record<string, any>) => {
      if (res.code == "1") {
        this.$message.success(res.message);
        this.getTenantPage();
      } else {
        this.$message.warning(res.message);
      }
    });
  }

  private showRoleSetting(row: TenantModel): void {
    this.roleSettingVisible = true;
    this.selectedTenant = row;
    this.initRoleList();
  }

  private setRole(): void {
    SetTenantSysRole(this.selectedTenant.id, this.selectedTenant.sysRoleId).then(
      (res: Record<string, any>) => {
        if (res.code == "1") {
          this.$message.success(res.message);
        } else {
          this.$message.warning(res.message);
        }
      }
    );
  }

  private showRoleForm(): void {
    this.roleFormDiglogVisible = true;
  }
  /**
   * 跳转至某页
   */
  changePage(page) {
    let totalPage = this.total / this.pageSize;
    let remainder = this.total % this.pageSize;
    if (remainder != 0) {
      totalPage++;
    }

    if (page < 1) {
      this.pageIndex = 1;
    } else if (page > totalPage) {
      this.pageIndex = totalPage;
    } else {
      this.pageIndex = page;
    }
    this.getTenantPage();
  }
  /**
   * 跳转至某页
   */
  changePageSize(pageSize) {
    if (pageSize < 1) {
      this.pageSize = 1;
    } else if (pageSize >= this.total) {
      this.pageSize = this.total;
    } else {
      this.pageSize = pageSize;
    }
    this.getTenantPage();
  }
}
