















































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { tenantAddEdit } from '@/api/system/TenantApi';
@Component({
  name: 'TenantForm'
})
export default class TenantForm extends Vue {
  // ref
  @Ref() readonly formRef;
  // 弹窗显隐
  @Prop({ default: false }) private visible!: boolean;
  //   当前数据
  @Prop({ default: undefined }) currtent;
  @Prop({ default: [] }) treeList: any;
  //   表单加载
  formLoading: boolean = false;
  //   表单数据
  tenantData: any = {};
  //   验证规则
  formRules: Record<string, any> = {
    name: {
      required: true,
      message: this.$t('lang_please_entry_a_tenant_name'),
      trigger: 'blur'
    },
    code: {
      required: true,
      message: this.$t('lang_please_entry_a_tenant_code'),
      trigger: 'blur'
    },
    type: {
      required: true,
      message: this.$t('lang_please_select_a_tenant_type'),
      trigger: 'change'
    },
    sort: {
      required: true,
      message: this.$t('lang_form_please_enter_a_sort'),
      trigger: 'blur'
    }
  };
  //   图片组件上传地址
  private uploadUrl: string = '/river-fs/file/create';
  // 图片组件预览地址
  private pubPreviewUrl: string = '/river-fs/file/view';

  created() {
    if (this.currtent) {
      this.tenantData = { ...this.currtent };
    } else {
      this.tenantData = {};
    }
  }

  save() {
    // 验证表单
    Promise.all([this.formRef.validate()]).then(() => {
      tenantAddEdit(this.tenantData)
        .then((res: Record<any, any>) => {
          if (res.code == '1') {
            this.$message.success(res.message);
            // 关闭弹窗
            this.handleCloseDialog();
            this.$emit('success');
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          // 关闭加载框
        });
    });
  }

  // 关闭弹窗
  handleCloseDialog() {
    this.$emit('success', {});
    this.updateVisible(false);
  }

  // 监听更新显示和隐藏
  updateVisible(value) {
    this.$emit('update:visible', value);
  }
  selectApplication(value: any) {
    this.tenantData['applicationId'] = value[value.length - 1];
  }

  // 获取显示值
  get dialogVisible() {
    return this.visible;
  }

  // 设置显示值
  set dialogVisible(newValue) {
    this.updateVisible(false);
  }
}
